<!-- 首页顶部header导航 -->
<template>
	<div class="tg-header">
		<div class="content">
			<div class="top-nav">
				<div class="logo">
					<img @click="toHome" v-if="themeName == 'default'" src="@/assets/images/main/logo-dark.png" alt="" />
					<img @click="toHome" v-else src="@/assets/images/main/logo-white.png" alt="" />
				</div>
				<div class="nav-lf">
					<el-menu router :default-active="this.$store.state.headerSelectName" class="el-menu-demo"
                   style="display:flex;"
						mode="horizontal" @select="handleSelect">
						<el-menu-item index="/recommend">{{$t('recommend.recommendhome')}}</el-menu-item>
						<el-menu-item index="/musicLibrary">{{$t('musiclibrary')}}</el-menu-item>
						<el-menu-item index="/activity" style="position: relative;">
							{{$t('activityarea')}}
							<div class="icon-free">free</div>
						</el-menu-item>
            <el-menu-item index="/musicai">{{$t('musicAI')}}</el-menu-item>
						<el-menu-item index="/customization" v-if="$store.state.identity == 2">
							{{$t('songcustomization')}}</el-menu-item>
					</el-menu>
				</div>
				<div class="nav-option">
					<div class="search header-option-right">
						<input class="search-input" :placeholder="$t('searchprompt')" type="text"
							v-model="$store.state.title" />
						<div @click="searchDemo" class="search-btn">
							<div class="btn">
								<i class="el-icon-search"></i>
							</div>
						</div>
					</div>
					<div class="upload header-option-right pointer" v-if="$store.state.identity != 2 && loginstaute == 1">
						<div class="upload-text" @click="uploadOpus">{{$t('workupload')}}</div>
					</div>
					<div class="header-option-right">

						<el-dropdown trigger="hover" placement="bottom" class="">
							<Icon name="icon-7key-musicqiehuanyuyan" theme-color="messageIconColor" />
							<el-dropdown-menu slot="dropdown" class="language-popover" ref="messageDropdown">
								<div v-for="item in languageList" @click="changLanguage(item)" class="pointer"
									:key="item.value">{{ item.name }}</div>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="message header-option-right">
						<el-dropdown trigger="click" placement="bottom" class="">
							<el-badge :is-dot="isDot" class="item">
								<i class="el-icon-bell message-icon pointer" @mouseover="messageshow"></i>
							</el-badge>
							<el-dropdown-menu slot="dropdown" class="message-popover" ref="messageDropdown">
								<!-- 消息提示弹窗 -->
								<!-- 消息中心 -->
								<div class="message-popover-box">
									<div class="message-popover-title">{{$t('messagecenter')}}</div>
								</div>
								<!-- item总盒子 -->
								<!-- <div class="message-tg-header-item">
                  <div class="message-popover-item" v-for="(item, index) in 6" :key="index">
                    <div class="message-item-img"></div>
                    <div class="message-item-box">
                      <div class="message-item-data">2020-09-02</div>
                      <div class="message-item-content">
                        您刚发布的名为《Madge
                        Owens》的歌曲因作品质量不符合平台标准，未审核通过，请调整作品后再次上传
                      </div>
                    </div>
                  </div>
                  <div class="message-more">
                    <div class="message-no-more">没有更多了</div>
                  </div>
                </div> -->
								<div class="message-tg-header-item" v-if="myMsg != ''">
									<div class="message-popover-item" v-for="(item, index) in myMsg" :key="index">
										<div class="message-item-img">
											<!-- <Icon name="icon-7key-musicxiaoxi" theme-color="iconColorAll" width="28"
												height="28"></Icon> -->
											<Icon name="icon-7key-musicxiaoxi" v-if="themeName == 'default'" color="#999999" width="28" height="28"></Icon>
											<Icon name="icon-7key-musicxiaoxi" v-if="themeName == 'dark'" color="#FFFFFF" width="28" height="28"></Icon>
										</div>
										<div class="message-item-box">
											<div class="message-item-data">{{item.created_at}}</div>
											<div class="message-item-content" v-html="item.msg_content">

											</div>
										</div>
									</div>
									<div class="message-more">
										<div class="message-no-more">{{$t('nomore')}}</div>
									</div>
								</div>

								<!-- 无消息 -->
								<el-empty v-if="myMsg == ''" :image-size="100"></el-empty>
							</el-dropdown-menu>
						</el-dropdown>
						<div ref="messageView"></div>
					</div>
					<div class="head header-option-right" @click="headshow">
						<el-dropdown trigger="click" placement="bottom" class="">
							<img :src="$store.state.userImage" @click="toPersonal" alt="">
							<el-dropdown-menu slot="dropdown" class="user-head-popover">
								<!-- 登录状态 -->
								<div v-if="loginstaute == 1">
									<div class="user-head-popover-box">
										<div class="user-head-header">
											<!-- {{$store.state.userImage}} -->
											<img class="user-head-header-img" :src="$store.state.userImage"
												@click="toPersonal" alt="">
										</div>
										<div class="user-head-header-rg">
											<div class="user-head-header-type">{{$store.state.loginname}}</div>
											<div class="user-head-header-content">{{$store.state.introduce}}</div>
										</div>
										<!-- 已认证 -->
										<div class="approve" v-if="$store.state.identity != 1">
											<div class="approve-p">{{$t('authenticated')}}</div>
											<Icon name="icon-7key-musicguanzhuchenggong" color="#00BEA5" width="14"
												height="14"></Icon>
											<!-- <div class="approve-img"></div> -->
										</div>
										<!-- 未认证 -->
										<div v-if="$store.state.identity == 1">
											<div @click="toAuth" class="user-head-no-approve">
												<div class="user-head-no-approve-p">{{$t('unverified')}}</div>
												<div class="user-head-no-approve-img"></div>
											</div>
										</div>

									</div>
									<div class="user-head-icon-box" v-if="$store.state.identity == 3">
										<div class="user-head-icon-item" v-for="(item, index) in approvelist"
											:key="index" @click="tapcomplete(item)">
											<div class="user-head-icon">
												<!-- <Icon
                            :name="item.icon"
                            color="#666666"
                            width="30"
                            height="30"
							v-if="themeName == 'default'"
                        ></Icon> -->
												<Icon :name="item.icon" v-if="themeName == 'dark'" color="#FFFFFF" width="30" height="30"></Icon>
												<Icon :name="item.icon" v-if="themeName == 'default'" color="#999999" width="30" height="30"></Icon>
											</div>
											<div class="user-head-name">
												{{ item.name }}
											</div>
										</div>
									</div>
									<div class="user-head-icon-box" v-if="$store.state.identity == 2">
										<div class="user-head-icon-item" v-for="(item, index) in approvelist1"
											:key="index" @click="tapcomplete(item)">
											<div class="user-head-icon">
												<Icon :name="item.icon" v-if="themeName == 'dark'" color="#FFFFFF" width="30" height="30"></Icon>
												<Icon :name="item.icon" v-if="themeName == 'default'" color="#999999" width="30" height="30"></Icon>
											</div>
											<div class="user-head-name">
												{{ item.name }}
											</div>
										</div>
									</div>
									<div class="user-head-icon-box" v-if="$store.state.identity == 1">
										<div class="user-head-icon-item" v-for="(item, index) in approvelist2"
											:key="index" @click="tapcomplete(item)">
											<div class="user-head-icon">
												<Icon :name="item.icon" v-if="themeName == 'default'" color="#999999" width="30" height="30"></Icon>
												<Icon :name="item.icon" v-if="themeName == 'dark'" color="#FFFFFF" width="30" height="30"></Icon>
											</div>
											<div class="user-head-name">
												{{ item.name }}
											</div>
										</div>
									</div>
									<div class="user-head-switch">
										<!-- <div class="user-head-language">切换语言/切换模式</div> -->
										<div class="user-head-guit" @click="exit">{{$t('logout ')}}</div>
									</div>
								</div>

								<!-- 未登录状态 -->
								<div v-if="loginstaute == 0">
									<div class="user-head-popover-box">
										<div class="user-head-header"></div>
										<div class="user-head-header-rg">
											<div class="user-head-header-type">{{$t('notlogin')}}</div>
											<div class="user-head-header-content">{{$t('loginmore')}}</div>
										</div>
									</div>
									<div class="user-head-icon-box">
										<div class="user-head-icon-item" v-for="(item, index) in list" :key="index"
											@click="tapdetail(item)">
											<div class="user-head-icon">
												<Icon :name="item.icon" theme-color="iconColorAll" width="30"
													height="30"></Icon>
											</div>
											<div class="user-head-name">
												{{ item.name }}
											</div>
										</div>
									</div>
									<div class="user-head-login-btn" @click="loginbtn">
										<div class="user-head-login-p">{{$t('promptlylogin')}}</div>
									</div>
								</div>

							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>

		</div>


	</div>
</template>

<script>
	import {getTheme} from "@/utils/common";
	import {
		setTheme
	} from "@/assets/theme";
	import {
		getToken,
		removeToken
	} from "@/utils/common";
	import {
		getUserInfo,
		setMyInfo,
		getMyInfo,
	} from "@/utils/common/user";
	import {
		getMessage
	} from "@/api/Home"
	// import { getUserInfo } from "@/utils/common/user";
	import {
		myInfo
	} from "@/api/Mine";

	export default {
		name: "Header",
		props: {},
		data() {
			return {
				isDot: false,
				visible: false, //登录成功弹窗是否显示
				keywords: '', //搜索关键字
				loginstaute: "",
				// approvelist: [],
				approvelist: [{
						name: `${this.$t('mywallet')}`,
						icon: "icon-7key-musicqianbao",
						id: 1,
					},
					{
						name: `${this.$t('myworks')}`,
						icon: "icon-7key-musicruzhu",
						id: 2,
					},
					{
						name: `${this.$t('myinterest')}`,
						icon: "icon-7key-musicguanzhu-2",
						id: 3,
					},
					{
						name: `${this.$t('mylove')}`,
						icon: "icon-7key-musicshoucang",
						id: 4,
					},
					{
						name: `${this.$t('myclaim')}`,
						icon: "icon-7key-musicrenling",
						id: 5,
					},
					// {
					// 	name: `${this.$t('mypurchase')}`,
					// 	icon: "icon-7key-musiczuopingoumai",
					// 	id: 6,
					// },
					// {
					// 	name: `${this.$t('mycustom')}`,
					// 	icon: "icon-7key-musicdingzhi",
					// 	id: 7,
					// },
					{
						name: `${this.$t('myaccount')}`,
						icon: "icon-7key-musicguanli",
						id: 8,
					},
				],
				// approvelist: [],
				approvelist1: [{
						name: `${this.$t('mywallet')}`,
						icon: "icon-7key-musicqianbao",
						id: 1,
					},
					// {
					//   name: "我的作品",
					//   icon: "icon-7key-musicruzhu",
					//   id: 2,
					// },
					{
						name: `${this.$t('myinterest')}`,
						icon: "icon-7key-musicguanzhu-2",
						id: 3,
					},
					{
						name: `${this.$t('mylove')}`,
						icon: "icon-7key-musicshoucang",
						id: 4,
					},
					// {
					//   name: "我的认领",
					//   icon: "icon-7key-musicrenling",
					//   id: 5,
					// },
					{
						name: `${this.$t('mypurchases')}`,
						icon: "icon-7key-musiczuopingoumai",
						id: 6,
					},
					{
						name: `${this.$t('mycustomize')}`,
						icon: "icon-7key-musicdingzhi",
						id: 7,
					},
					{
						name: `${this.$t('myaccount')}`,
						icon: "icon-7key-musicguanli",
						id: 8,
					},
				],
				approvelist2: [{
						name: `${this.$t('mywallet')}`,
						icon: "icon-7key-musicqianbao",
						id: 1,
					},
					// {
					//   name: "我的作品",
					//   icon: "icon-7key-musicruzhu",
					//   id: 2,
					// },
					{
						name: `${this.$t('myinterest')}`,
						icon: "icon-7key-musicguanzhu-2",
						id: 3,
					},
					{
						name: `${this.$t('mylove')}`,
						icon: "icon-7key-musicshoucang",
						id: 4,
					},
					// {
					//   name: "我的认领",
					//   icon: "icon-7key-musicrenling",
					//   id: 5,
					// },
					{
						name: `${this.$t('mypurchases')}`,
						icon: "icon-7key-musiczuopingoumai",
						id: 6,
					},
					// {
					// 	name: `${this.$t('mycustomize')}`,
					// 	icon: "icon-7key-musicdingzhi",
					// 	id: 7,
					// },
					{
						name: `${this.$t('myaccount')}`,
						icon: "icon-7key-musicguanli",
						id: 8,
					},
				],
				list: [{
						name: `${this.$t('mannedplatform')}`,
						icon: "icon-7key-musicruzhu",
						id: 1,
					},
					{
						name: `${this.$t('uploadingworks')}`,
						icon: "icon-7key-musicshangchuan2",
						id: 2,
					},
					{
						name: `${this.$t('workspurchase')}`,
						icon: "icon-7key-musiczuopingoumai",
						id: 3,
					},
					{
						name: `${this.$t('songcustomization')}`,
						icon: "icon-7key-musicdingzhi",
						id: 4,
					},
				],
				headconShow: false,
				conShow: false,
				themeName: "",
				headerActive: "/recommend",
				languageList: [{
						name: '中国',
						value: 'cn'
					},
					{
						name: '한국어',
						value: 'ko'
					},
					{
						name: 'English',
						value: 'en'
					}
				],
				myMsg: [], //消息列表
				// loginname: '',
			};
		},
		components: {},
		computed: {},
		created() {
			//console.log('header created')
			// console.log(this.getToken(), "是否登录",JSON.parse(this.getUserInfo()));
			// loginstaute :0未登录 1登录
			if (this.getToken() == undefined) {
				this.loginstaute = 0;
			} else if (this.getToken() != "") {
				this.loginstaute = 1;
				this.getMessage()
			}
			console.log(this.loginstaute, "当前状态");

			// window.addEventListener("beforeunload",()=>{
			//     window.localStorage.setItem("loginname",this.$store.state.loginname)
			// 	this.loginname = window.localStorage.getItem("loginname")
			// 	console.log(window.localStorage.getItem("loginname"),'window.localStorage.getItem("loginname")')
			// })
		},
		mounted() {
			this.themeName = this.getTheme()
			// console.log('header mounted',this.themeName)
			
			const that = this;
			window.addEventListener("setItemEvent", function(e) {
				// console.log(e.newValue.length,'555555555555555');
				if(e.newValue.length > 10){
					if((typeof JSON.parse(e.newValue) == 'object')){
						// console.log('999999999999999999');
						that.loginstaute = 1
					}
				}else {
					if(e.newValue == 'default' || e.newValue == 'dark'){
						that.themeName = e.newValue;
					}
					
					if(e.newValue == 'en' || e.newValue == 'cn' || e.newValue == 'ko'){
						that.$router.go(0)
						// console.log('6666666666666666')
					}
				}
				
				
				// that.themeName = e.newValue;
				// that.themeName = localStorage.getItem("theme");
			});
			this.themeName = localStorage.getItem("theme");
			this.headerActive = this.$route.path;
			// console.log(this.$store.state.identity)
			// console.log(this.myMsg.length, 'mmmmmmmmmmmmmmmmmmmmmmmmm');
			if (this.myMsg.length > 0) {
				this.isDot = true
			} else {
				this.isDot = false
			}

			// this.MyInfo()
		},
		watch: {
			themeName: {
				handler(newValue, oldValue) {
					console.log('themeName11111', newValue)
					this.themeName = newValue
					// console.log(this.GlobalCSS[newValue])
					// this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
				}
			},
			
		},
		methods: {
			getToken,
			setTheme,
			getUserInfo,
			getTheme,
			// setMyInfo,
			// getMyInfo,
			// MyInfo(){
			// 	myInfo({}).then((res) => {
			// 	      console.log(res,'我的信息成功');
			// 		  // this.infoObj = res.data
			// 		  this.setMyInfo(res.data)
			// 		  console.log(this.getMyInfo(),'getMyInfo')
			// 	    })
			// 	    .catch((err) => {
			// 	      console.log(err,'我的信息失败');
			// 	    });
			// },
			toPersonal() {
				this.$router.push({
					path: "/personalCenter"
				});
			},
			// 去认证
			toAuth() {
				this.$router.push({
					path: "/uploadopus/musiciancertification"
				});

			},
			// 消息列表
			getMessage() {
				// this.new_count = 1;
				this.new_count = "";
				let params = {};
				getMessage().then((res) => {
					this.myMsg = res.list;
					if (this.myMsg.length > 0) {
						this.isDot = true
					} else {
						this.isDot = false
					}
				});

			},
			// 搜索beat
			// searchBeat() {
			// 	console.log(this.keywords, 'searchBeat')
			// 	this.$router.push({
			// 		path: "/musicLibrary/workcollaboration",
			// 		query: {
			// 			title: this.keywords
			// 		}
			// 	});
			// },
			// 已登录状态个人中心跳转
			tapcomplete(item) {
				// 我的钱包
				if (item.id == 1) {
					this.$router.push({
						path: "/personalCenter/myWallet/totalRevenue",
					});
				} else if (item.id == 2) {
					// 我的作品
					this.$router.push({
						path: "/personalCenter/myWork/workproducthmusic",
					});
				} else if (item.id == 3) {
					// 我的关注
					this.$router.push({
						path: "/personalCenter/myAttention",
					});
				} else if (item.id == 4) {
					// 我的收藏
					this.$router.push({
						path: "/personalCenter/myCollection/collectMusic",
					});
				} else if (item.id == 5) {
					// 我的认领
					this.$router.push({
						path: "/personalCenter/myClaim/complete",
					});
				} else if (item.id == 6) {
					// 我的购买
					this.$router.push({
						path: "/personalCenter/MyPurchase/Purchased",
					});
				} else if (item.id == 7) {
					// 我的定制
					this.$router.push({
						path: "/personalCenter/MyCustomization/CustomUnfinished",
					});
				} else if (item.id == 8) {
					// 账户管理
					this.$router.push({
						path: "/personalCenter/accountManagement/personInformation",
					});
				}
			},
			// 登录按钮
			loginbtn() {
				this.$router.push("/login/vcodelogin");
			},
			// 未登录状态个人中心跳转
			tapdetail(item) {
				// 入驻平台
				if (item.id == 1) {
					this.$router.push({
						path: "/personalCenter/teamManagement/createTeam",
					});
				} else if (item.id == 2) {
					// 上传作品
					this.$router.push({
						path: "/uploadopus/finishmusic",
					});
				} else if (item.id == 3) {
					// 作品购买
					this.$router.push({
						path: "/musicLibrary/producthmusic",
					});
				} else if (item.id == 4) {
					// 歌曲制定
					this.$router.push({
						path: "/customization",
					});
				}
			},
			changLanguage(item) {
				this.$i18n.locale = item.value;
				localStorage.setItem("lang", item.value);
			},
			//头像
			headshow() {
				this.themeName = localStorage.getItem("theme");
				// this.loginstaute = '';
				if (this.getToken() == undefined) {
					this.loginstaute = 0;
				} else if (this.getToken() != "") {
					this.loginstaute = 1;
					// this.getMessage()
				}
				this.headconShow = !this.headconShow;
			},
			//消息
			messageshow() {
				// this.conShow = true;
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath, 'jajajajaaj');
				// this.headerActive = key;
			},
			// 上传作品
			uploadOpus() {
				console.log(JSON.parse(this.getUserInfo()), ',,,,,,,,,,,,,,,,,,,,,,,,,,,',this.$store.state.identity)
				let userInfo = JSON.parse(this.getUserInfo())
				if (this.$store.state.identity == 3) {
					this.$router.push({
						path: "/uploadopus/finishmusic",
					});

				} else {
					this.$message({
						type: "warning",
						message: this.$t("UploadOpus.noRegisteredMusician"),
					});
					this.$router.push({
						path: "/uploadopus/musiciancertification",
					});
				}


			},
			searchDemo() {
				console.log(this.$route.path, 'searchDemo')
				// this.$store.state.demo_title = this.demoTitle;
				// console.log(this.$route.path);
				if (this.$route.path == "/musicLibrary/seademo") {
					// this.$store.state.title = this.keywords;
					this.$router.push({
						path: "/musicLibrary/seademo",
						query: {
							title: this.$store.state.title
						},
					});
				} else if (this.$route.path == "/musicLibrary/inlanddemo") {
					// this.$store.state.title = this.keywords;
					this.$router.push({
						path: "/musicLibrary/inlanddemo",
						query: {
							title: this.$store.state.title
						},
					});
				} else if (this.$route.path == "/musicLibrary/producthmusic") {
					// this.$store.state.title = this.keywords;
					this.$router.push({
						path: "/musicLibrary/producthmusic",
						query: {
							title: this.$store.state.title
						},
					});
				} else if (this.$route.path == "/musiclibrary/librarydemob") {
					// this.$store.state.title = this.keywords;
					this.$router.push({
						path: "/musiclibrary/librarydemob",
						query: {
							title: this.$store.state.title
						},
					});
				} else {
					{
						// this.$store.state.title = this.keywords;
						if(this.$store.state.identity == 3){
							this.$router.push({
								path: "/musicLibrary/workcollaboration",
								query: {
									title: this.$store.state.title
								},
							});
						}else {
							this.$router.push({
								path: "/musicLibrary/seademo",
								query: {
									title: this.$store.state.title
								},
							});
						}
						
					}
				}
			},
			// 点击logo可以跳回首页
			toHome(){
				this.$router.push({
				  path: '/recommend'
				})
			},
			// 退出登录
			removeToken,
			exit() {
				console.log(this.removeToken(), 'this.removeToken()')
				this.removeToken('7key_token')
				this.loginstaute = 0
				this.$store.state.playShow = false
				let DemoseaList = {}
				this.$set(DemoseaList, 'playStatus', true)
				this.$store.commit('changeMusicInfo', DemoseaList);
				this.$router.go(0)
				this.$router.push("/login/vcodelogin");
				
			},
		},
	};
</script>

<style scoped lang="less">
	.tg-header {
		width: 100%;
		height: 78px;
		background: @baseBackgroundColor;
		backdrop-filter: blur(20px);
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

		.content {
			width: 62.5%;
			min-width: 1100PX;
			margin: auto;
			position: relative;
		}


		.top-nav {
			width: 100%;
			height: 78px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.nav-lf {
				width: 50%;
				height: 78px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-left: 50px;

				.icon-free {
					position: absolute;
					width: 40px;
					height: 18px;
					background: #ff405c;
					border-radius: 9px;
					position: absolute;
					top: -2px;
					right: 0;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				::v-deep .el-menu {
					background: rgba(255, 255, 255, 0);

					&.el-menu--horizontal {
						border-bottom: none;
					}
				}

				::v-deep .el-menu-item {
					border-bottom: none;
					text-decoration: none;
					list-style: none;
					font-size: 16px;
					font-weight: 400 !important;
					color: #999999 !important;

					&.is-active {
						background: rgba(255, 255, 255, 0);
						color: @headerSelectTextColor  !important;
						font-size: 16px;
						font-weight: 600 !important;
					}

					&:hover {
						background: rgba(255, 255, 255, 0);
						color: @headerSelectTextColor  !important;
						font-size: 16px;
						font-weight: 600 !important;
					}
				}
			}

			.nav-option {
				width: 50%;
				height: 50px;
				display: flex;
				justify-content: right;
				align-items: center;

				.header-option-right {
					margin-left: 24px;
				}
			}

			.logo {
				width: 79px;
				height: 36px;
				cursor: pointer;

				img {
					width: 79px;
					height: 36px;
				}
			}

			.search {
				width: 267px;
				height: 38px;
				background: @headerSearchBg;
				border-radius: 6px;
				margin-left: 20px;
				position: relative;

				::v-deep .el-icon-search:before {
					content: "\e778";
					color: #ffffff;
					font-size: 19px;
				}

				::v-deep .el-icon-bell:before {
					content: "\e725";
					color: #ffffff;
					font-size: 20px;
				}

				.search-input {
					width: 180px;
					height: 36px;
					border-radius: 6px;
					background-color: @headerSearchBg;
					border: 1px solid transparent;
					outline: none;
					padding: 0px 10px;
					color: @headerTextColor;
				}

				.search-btn {
					width: 50px;
					height: 32px;
					background: #3370ff;
					border-radius: 6px;
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					align-items: center;
					justify-self: center;
					margin: 3px;
					cursor: pointer;

					&:hover {
						opacity: 0.9;
					}

					.btn {
						width: 20px;
						height: 20px;
						margin: auto;
					}
				}
			}

			.message {
				width: 22px;
				height: 24px;

				.message-icon {
					font-size: 24px;
					color: @messageIconColor;
				}
			}

			.upload {
				width: 21%;
				height: 36px;
				background: #3370ff;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-self: center;

				&:hover {
					opacity: 0.9;
				}

				.upload-text {
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #ffffff;
					margin: auto;
          white-space: nowrap;
				}
			}

			.head {
				width: 30px;
				height: 30px;
				// background-color: skyblue;
				border-radius: 20px;
				cursor: pointer;

				img {
					width: 30px;
					height: 30px;
					// background-color: skyblue;
					border-radius: 20px;
				}
			}
		}
	}
</style>
<style lang="less">
	/* 消除小三角 */
	.el-popper[x-placement^=bottom] .popper__arrow {
		border: none;
	}

	.el-popper[x-placement^=bottom] .popper__arrow::after {
		border: none;
	}

	.language-popover {
		width: 100px;
		// background: rgba(25, 28, 50, 0.6);
		background-color: @loginPopColor;
		border-radius: 0px 0px 6px 6px;
		border: 1px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(50px);

		div {
			width: 100%;
			height: 20px;
			padding: 10px 0;
			text-align: center;
			color: @uploadFormTextColor;

			&:hover {
				opacity: .6;
			}
		}
	}

	.message-popover {
		width: 440px;
		height: 390px;
		margin-top: 20px;
		// background: rgba(25, 28, 50, 0.6);
		background: @loginPopColor;
		border-radius: 0px 0px 6px 6px;
		border: 1px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(50px);

		&::-webkit-scrollbar {
			display: none;
		}

		.message-popover-box {
			width: 400px;
			height: 40px;
			margin: auto;
			// border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			border-bottom: 1px solid @messageLineColor;

			.message-popover-title {
				margin-top: 15px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				// color: #FFFFFF;
				color: @recommendMusicianListTitleColor;
			}
		}

		.message-tg-header-item {
			width: 400px;
			height: 300px;
			margin: auto;
			overflow-y: scroll;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				display: none;
			}

			.message-popover-item {
				width: 400px;
				height: 90px;
				margin: auto;
				display: flex;
				border-bottom: 1px solid @messageLineColor;

				.message-item-img {
					width: 40px;
					height: 38px;
					background: @messageLineColor;
					margin-top: 12px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding-top: 2px;
					
				}

				.message-item-box {
					width: 40px;
					height: 90px;
					margin-left: 10px;

					.message-item-data {
						transform: scale(0.8);
						transform-origin: left;
						width: 100px;
						height: 18px;
						font-size: 10px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: @recommendMusicianListTitleColor;
						line-height: 17px;
						margin-top: 12px;
					}

					.message-item-content {
						text-align: justify;
						transform: scale(0.9);
						transform-origin: left;
						width: 360px;
						height: 44px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: @recommendMusicianListTitleColor;
						line-height: 22px;
					}
				}
			}
		}

		.message-more {
			width: 420px;
			height: 20px;
			display: flex;
			justify-content: center;

			.message-no-more {
				margin-top: 10px;
				width: 120px;
				height: 22px;
				transform: scale(0.8);
				transform-origin: left;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: @recommendMusicianListTitleColor;
				line-height: 22px;
			}
		}

	}

	.user-head-popover {
		width: 440px !important;
		height: 322px !important;
		// background: rgba(25, 28, 50, 0.6);
		background: @loginPopColor;
		
		border-radius: 0px 0px 6px 6px;
		border: 1px solid rgba(255, 255, 255, 0.08);
		backdrop-filter: blur(50px);

		.user-head-popover-box {
			width: 400px;
			height: 80px;
			margin: auto;
			display: flex;
			// border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			border-bottom: 1px solid @messageLineColor;
		}

		.user-head-icon-box {
			width: 410px;
			height: 160px;
			margin: auto;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			.user-head-icon-item {
				width: 100px;
				height: 80px;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;

				.user-head-icon {
					width: 40px;
					height: 40px;
					margin: auto;
					margin-top: 20px;
					display: flex;
					justify-content: center;
				}

				.user-head-name {
					// width: 66px;
					height: 30px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					margin: auto;
					font-weight: 400;
					// color: #ffffff;
					color: @recommendMusicianListTitleColor;
					line-height: 30px;
				}
			}
		}

		.user-head-switch {
			width: 410px;
			height: 50px;
			margin: auto;
			display: flex;
			// justify-content: space-between;
			justify-content: flex-end;
			// border-top: 1px solid gray;
			border-top: 1px solid @messageLineColor;
			margin-top: 20px;

			.user-head-language {
				width: 130px;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.6);
				line-height: 17px;
				margin-top: 20px;
			}

			.user-head-guit {
				width: 60px;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.6);
				line-height: 17px;
				margin-top: 20px;
				cursor: pointer;
				color: @recommendMusicianListTitleColor;
			}
		}

		.user-head-login-btn {
			width: 392px;
			height: 36px;
			background: #3370ff;
			border-radius: 4px;
			margin: auto;
			cursor: pointer;

			.user-head-login-p {
				margin: auto;
				// width: 75px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				letter-spacing: 1px;
				line-height: 40px;
				display: flex;
				justify-content: center;
			}
		}

		.user-head-header {
			width: 44px;
			height: 44px;
			margin-top: 15px;
			// background-color: #b4b6bb;
			border-radius: 50px;
			cursor: pointer;

			.user-head-header-img {
				width: 44px;
				height: 44px;
				// margin-top: 15px;
				// background-color: #b4b6bb;
				border-radius: 50px;
				cursor: pointer;
			}
		}

		.approve {
			width: 88px;
			height: 36px;
			background: #E0F9F6;
			border-radius: 4px;
			border: 1px solid rgba(0, 190, 165, 0.2);
			// text-align: center;
			// line-height: 36px;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #00BEA5;
			margin-top: 20px;
			margin-left: 30px;
			display: flex;
			align-items: center;
			justify-content: center;

			.approve-p {
				margin-right: 4px;
			}
		}

		.user-head-header-rg {
			width: 200px;
			height: 60px;
			margin-top: 10px;
			margin-left: 15px;
		}

		.user-head-no-approve {
			width: 90px;
			height: 36px;
			background: #ffeaee;
			border-radius: 4px;
			border: 1px solid rgba(255, 64, 92, 0.2);
			margin-top: 20px;
			display: flex;
			padding: 0 15px 0 0px;
			margin-left: 30px;
			cursor: pointer;
			white-space: nowrap;
			position: relative;

			.user-head-no-approve-p {
				transform: scale(0.7);
				width: 70px;
				height: 30px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ff405c;
				line-height: 35px;
				// margin-right: 20px;
				position: absolute;
			}

			.user-head-no-approve-img {
				width: 15px;
				height: 15px;
				background-color: #ff405c;
				border-radius: 50px;
				margin-top: 3px;
				position: absolute;
				right: 6px;

			}
		}

		.user-head-approve {
			width: 80px;
			height: 36px;
			background: #e0f9f6;
			border-radius: 4px;
			border: 1px solid rgba(0, 190, 165, 0.2);
			margin-top: 20px;
			display: flex;
			padding: 0 10px 0 10px;
			margin-left: 30px;

			.user-head-approve-p {
				transform: scale(0.7);
				width: 70px;
				height: 30px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #00bea5;
				line-height: 35px;
			}

			.user-head-approve-img {
				width: 15px;
				height: 15px;
				background-color: #00bea5;
				border-radius: 50px;
				margin-top: 3px;
			}
		}

		.user-head-header-content {
			margin-top: 10px;
			width: 170px;
			height: 17px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.6);
			line-height: 17px;
			color: @recommendMusicianListTitleColor;
		}

		.user-head-header-type {
			width: 90px;
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			// color: #ffffff;
			color: @recommendMusicianListTitleColor;
			line-height: 22px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
</style>
